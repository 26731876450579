export interface CIAMToken {
  scope: string;
  authorization_details: [];
  client_id: string;
  iss: string;
  jti: string;
  acr: string;
  aud: string[];
  sub: string;
  ciam_id: string;
  persona_id: string;
  exp: number;
  linkItId?: string;
  linkitid?: string;
  /**
   * This claim will be present if the customer has any NetCracker products.
   */
  individual_id?: string;
}

/**
 * Parse JWT token and return its payload
 * @param token JWT token
 * @returns Token payload
 */
export const parseJwt = (token: string): CIAMToken | null => {
  try {
    return JSON.parse(window.atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const LOGOUT_ALL_DEVICES_PATH = '/logout';
